<template lang="pug">
  v-switch(
    v-bind="$props"
    @change="$emit('change', $event)"
    :readonly="readonly"
  ).hold-switcher
</template>

<script>
export default {
  name: 'Switcher',
  props: {
    value: Boolean,
    label: String,
    color: String,
    inputValue: Boolean,
    disabled: Boolean,
    hideDetails: Boolean,
    falseValue: Boolean,
    loading: Boolean,
    readonly: Boolean
  }
}
</script>

<style lang="scss" scoped>
.hold-switcher::v-deep  {
  &.v-input--selection-controls {
    margin-top: 0px;
  }
  & .v-input--switch__thumb.v-input--switch__thumb.v-input--switch__thumb  {
    color:$warning-color;
  }
  & .v-input--switch__track.v-input--switch__track.v-input--switch__track {
    color: lighten($color: $warning-color, $amount: 2.5)
  }
}
</style>